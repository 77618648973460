var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show && _vm.resellers
    ? _c("div", { staticClass: "fixed top-0 left-0 w-full h-full wrapper" }, [
        _c("div", {
          staticClass:
            "overlay bg-black absolute top-0 left-0 w-full h-full opacity-50",
          on: { click: _vm.close }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-20 bg-white shadow-sm w-full centered reseller-modal"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "bg-green text-white px-6 py-4 text-lg font-bold relative"
              },
              [
                _vm._v(
                  "\n      Authorized Reseller" +
                    _vm._s(_vm.resellers.length > 1 ? "s" : "") +
                    "\n\n      "
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "text-white tr-opacity hover:opacity-50 float-right",
                    on: { click: _vm.close }
                  },
                  [
                    _c(
                      "base-icon",
                      {
                        attrs: {
                          "icon-name": "close",
                          width: "18",
                          height: "18",
                          viewbox: "0 0 23 23"
                        }
                      },
                      [_c("icon-x")],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "px-6 pt-4 pb-6" },
              [
                _vm._m(0),
                _vm._v(" "),
                _vm._l(_vm.resellers, function(reseller, r) {
                  return _c(
                    "div",
                    { key: r, class: r > 0 ? "mt-5" : "" },
                    [
                      _c(
                        "component",
                        _vm._b(
                          { staticClass: "block text-2xl font-bold" },
                          "component",
                          _vm.resellerNameProps(reseller),
                          false
                        ),
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(reseller.name) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      reseller.description
                        ? _c(
                            "div",
                            { staticClass: "text-base opacity-75 mt-1" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(reseller.description) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-1 text-base contact-details mt-2" },
                        [
                          reseller.contact.phoneNumber
                            ? _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(reseller.contact.phoneNumber) +
                                    "\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          reseller.contact.emailAddress
                            ? _c("span", [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "text-red tr-color hover:text-red-hover no-underline",
                                    attrs: {
                                      href:
                                        "mailto:" +
                                        reseller.contact.emailAddress
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Email\n            "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          reseller.contact.websiteUrl
                            ? _c("span", [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "text-red tr-color hover:text-red-hover no-underline",
                                    attrs: {
                                      href: _vm.formatUrl(
                                        reseller.contact.websiteUrl
                                      ),
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Website\n            "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                })
              ],
              2
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rich-text mb-4" }, [
      _c("p", [
        _vm._v(
          "\n          Please consider buying from one of our authorized resellers in your region. The pricing on our website does not include VAT, import fees or duties, which would be additional fees that we cannot estimate for you.\n          By purchasing through an authorized reseller, you will know exact costs and will not need to navigate import paperwork.\n        "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }