var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-detail-sections" },
    [
      _vm._l(_vm.$parent.detailSections, function(section, i) {
        return _c(
          "div",
          {
            key: i,
            class: [
              "product-detail-section mb-6",
              section.active ? "opened" : "closed"
            ],
            attrs: {
              "data-section-id": i,
              id: section.heading.replace(/\s+/g, "-").toLowerCase()
            }
          },
          [
            _c(
              "button",
              {
                class: [
                  "relative text-2xl xl:text-3xl font-bold tr-bg tr-color block border-2 border-brown w-full text-left pl-6 xl:pl-7 pr-11 py-2",
                  section.active
                    ? "active bg-c text-brown uppercase shadow-sm"
                    : "bg-brown text-white"
                ],
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    section.active = !section.active
                  }
                }
              },
              [
                _vm._v("\n      " + _vm._s(section.heading) + "\n      "),
                _c("span", {
                  staticClass: "arrow absolute centered-y right-0 mr-6"
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "slide-up-down",
              {
                staticClass: "px-2",
                attrs: { active: section.active, duration: 350 }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "bg-c border-l-2 border-b-2 border-r-2 border-brown p-4 xl:px-6 xl:py-5"
                  },
                  _vm._l(section.blocks, function(block, b) {
                    return _c(
                      "div",
                      { key: b },
                      [
                        _c(
                          "ProductDetail" +
                            _vm.capitalizeFirstLetter(block.type),
                          { tag: "component", attrs: { block: block } }
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ]
            )
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.$parent.accessories && _vm.$parent.accessories.length > 0
        ? _c(
            "div",
            {
              class: [
                "product-detail-section mb-6",
                _vm.active ? "opened" : "closed"
              ],
              attrs: { id: "Accessories" }
            },
            [
              _c(
                "button",
                {
                  class: [
                    "relative text-2xl xl:text-3xl font-bold tr-bg tr-color block border-2 border-brown w-full text-left pl-6 xl:pl-7 pr-11 py-2",
                    _vm.active
                      ? "active bg-c text-brown uppercase shadow-sm"
                      : "bg-brown text-white"
                  ],
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.active = !_vm.active
                    }
                  }
                },
                [
                  _vm._v("\n      Accessories\n      "),
                  _c("span", {
                    staticClass: "arrow absolute centered-y right-0 mr-6"
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "slide-up-down",
                {
                  staticClass: "px-2",
                  attrs: { active: _vm.active, duration: 350 }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "bg-c border-l-2 border-b-2 border-r-2 border-brown p-4 xl:px-6 xl:py-5"
                    },
                    _vm._l(_vm.$parent.accessories, function(accessory, i) {
                      return _c(
                        "div",
                        { key: i, attrs: { "data-section-id": i } },
                        [
                          _c(
                            "div",
                            {
                              class: [
                                "product-side-by-side-text-and-image border-t border-brown pt-6 mb-4 md:flex",
                                i === 0 ? "mt-6" : ""
                              ]
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-1 mb-6 w-full sm:w-1/4 md:mb-0"
                                },
                                [
                                  accessory.image.src
                                    ? _c("base-image", {
                                        attrs: {
                                          src: accessory.image.src,
                                          alt: accessory.image.alt
                                            ? accessory.image.alt
                                            : accessory.image.title,
                                          class_names: "mx-auto"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-2 md:flex md:pr-6 w-full sm:w-3/4 lg:pr-8 xl:pr-10"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex-auto" },
                                    [
                                      accessory.product_title !== ""
                                        ? _c("base-heading", {
                                            attrs: {
                                              size: "h2",
                                              color: "brown"
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                accessory.product_title
                                              )
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("base-rich-text", {
                                        attrs: {
                                          class_names:
                                            accessory.product_title !== ""
                                              ? "mt-2"
                                              : ""
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            accessory.product_description
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  accessory
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex-none md:w-64 mb-6 md:ml-6 lg:ml-8 xl:ml-10 mt-10 md:mt-0"
                                        },
                                        [
                                          _c("add-to-cart-simple", {
                                            attrs: {
                                              product: accessory.product
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }