var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col h-full" },
    [
      _c(
        "div",
        {
          staticClass:
            "border-grey border h-80 flex items-center justify-center"
        },
        [
          _c("base-image", {
            attrs: {
              src:
                _vm.accessory.src !== ""
                  ? _vm.accessory.src
                  : "/img/add-on-small-thumbnail-placeholder.png",
              retina_src:
                _vm.accessory.retinaSrc !== ""
                  ? _vm.accessory.retinaSrc
                  : "/img/add-on-small-thumbnail-placeholder@2x.png",
              height: 300,
              width: 250,
              class_names: "max-h-48 object-contain"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "py-4" },
        [
          _c(
            "base-heading",
            { attrs: { size: "h2", color: "brown", class_names: "my-2" } },
            [_vm._v("\n      " + _vm._s(_vm.accessory.title) + "\n    ")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: " mt-1 sm:mt-2 text-sm text-brown" }, [
            _vm._v("Product code:" + _vm._s(_vm.accessory.code))
          ]),
          _vm._v(" "),
          _vm.accessory.description !== ""
            ? _c(
                "base-rich-text",
                { attrs: { size: "small", class_names: "mt-3" } },
                [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.accessory.description) }
                  })
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "border-t border-b border-grey flex flex-wrap justify-between py-4 mt-auto"
        },
        [
          _c("div", [
            _c("div", { staticClass: "text-2xl font-bold" }, [
              _c("span", {
                staticClass: "text-brown",
                domProps: {
                  innerHTML: _vm._s(_vm.calculatedProductPrice(_vm.accessory))
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "text-brown mt-1" }, [
                _vm._v("(" + _vm._s(_vm.$root.formattedCurrency()) + ")")
              ])
            ]),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hasPriceOverride,
                  expression: "!hasPriceOverride"
                }
              ],
              staticClass: "text-sm uppercase text-brown mt-1 product-stock",
              domProps: { innerHTML: _vm._s(_vm.accessory.stock) }
            }),
            _vm._v(" "),
            !_vm.hasPriceOverride &&
            _vm.accessory.price_tiers_display.length > 1
              ? _c(
                  "div",
                  { staticClass: "relative mt-4" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "italic text-red text-sm underline",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.showQuantityDiscountBox = !_vm.showQuantityDiscountBox
                          }
                        }
                      },
                      [_vm._v("\n          Quantity Discount\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "slide-up-down",
                      {
                        staticClass: "price-tiers-box absolute right-0 z-10",
                        attrs: {
                          active: _vm.showQuantityDiscountBox,
                          duration: 0
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "py-2 px-3 bg-brown text-white" },
                          [
                            _c(
                              "table",
                              { staticClass: "mx-auto" },
                              _vm._l(
                                _vm.accessory.price_tiers_display,
                                function(tier, t) {
                                  return _c("tr", { key: t }, [
                                    _c("td", { staticClass: "text-right" }, [
                                      _vm._v(_vm._s(tier.label) + ":")
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "pl-3" }, [
                                      _vm._v(
                                        _vm._s(_vm.formattedPrice(tier.price)) +
                                          " (" +
                                          _vm._s(
                                            _vm.$root.formattedCurrency()
                                          ) +
                                          ")"
                                      )
                                    ])
                                  ])
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "text-left mt-2" },
                              [_vm._t("bulk_prices_text")],
                              2
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex items-center" },
            [
              _c("span", { staticClass: "mr-2 text-lg text-brown" }, [
                _vm._v("Qty:")
              ]),
              _vm._v(" "),
              _c("input-product-quantity", {
                attrs: {
                  value: _vm.quantity,
                  bg_color: "border-grey-lightest",
                  border_color: "undefined",
                  is_loading: _vm.isLoading,
                  is_disabled: _vm.is_disabled,
                  class_names: "mx-auto border-brown"
                },
                model: {
                  value: _vm.quantity,
                  callback: function($$v) {
                    _vm.quantity = $$v
                  },
                  expression: "quantity"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "w-full pt-4" }, [
            _c(
              "button",
              {
                class: [
                  "w-full text-white rounded uppercase py-3 px-16 font-bold text-lg xl:text-xl leading-tighter text-center tr-bg",
                  _vm.isLoading || _vm.quantity < 1
                    ? "bg-grey"
                    : "bg-red hover:bg-red-hover"
                ],
                attrs: {
                  type: "button",
                  disabled: _vm.isLoading || _vm.quantity < 1
                },
                on: {
                  click: function($event) {
                    return _vm.addToCart()
                  }
                }
              },
              [_vm._v("\n        Add to cart\n      ")]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("cart-overlay-single", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showOverlay,
            expression: "showOverlay"
          }
        ],
        attrs: { product: _vm.accessory }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }