<template>
    <section
        :class="[
            'block mixed-content-block',
            background_color,
            background_color === 'bg-white' ? 'white-block' : ''
        ]">
        <div :class="[
                include_container ? 'container mx-auto px-5-safe' : 'full-container',
                'py-8 lg:py-10 xl:py-12'
             ]">
            <div :class="hasSidebarBlocks ? 'lg:flex' : ''">
                <div :class="[
                    'content-column',
                    hasSidebarBlocks ? 'mb-10 lg:mb-0 lg:w-3/4 lg:pr-12 xl:pr-16' : ''
                ]">

                    <!-- heading -->
                    <div v-if="heading">
                        <base-heading size="h1">{{ heading }}</base-heading>
                    </div>

                    <!-- text -->
                    <div v-if="has_text">
                        <base-rich-text>
                            <template>
                                <slot name="text"></slot>
                            </template>
                        </base-rich-text>
                    </div>

                    <!-- facebook button -->
                    <div v-if="facebook_button" class="facebook-button inline-flex items-center text-lg">
                        <div>
                            <social-icon :url="facebook_button.url" type="facebook" class_names="inline-block"
                                         icon_size="32"></social-icon>
                        </div>
                        <div class="pl-3">
                            <a :href="facebook_button.url">
                                {{ facebook_button.text ? facebook_button.text : "Find us on Facebook" }}
                            </a>
                        </div>
                    </div>

                    <!-- image -->
                    <div v-if="image">
                        <base-image
                            :src="image.src"
                            :retina_src="image.retina"
                            :srcset_webp="image.webpSrcset"
                            class_names="mx-auto"
                            :alt="image.alt"
                        ></base-image>
                    </div>

                </div>

                <!-- sidebar -->
                <div class="lg:w-1/4" v-if="hasSidebarBlocks">
                    <sidebar-blocks :blocks="sidebar_blocks"></sidebar-blocks>
                </div>
              </div>
        </div>
    </section>
</template>

<script>
  // @group Blocks
  export default {
    props: ['background_color', 'heading', 'facebook_button', 'image', 'has_text', 'sidebar_blocks', "include_container"],
    components: {
      BaseHeading: () => import(/* webpackChunkName: "base-heading" */ "../components/BaseHeading.vue"),
      BaseImage: () => import(/* webpackChunkName: "base-image" */ "../components/BaseImage.vue"),
      BaseRichText: () => import(/* webpackChunkName: "base-rich-text" */ "../components/BaseRichText.vue"),
      SidebarBlocks: () => import(/* webpackChunkName: "product-sidebar" */ "../sidebar/SidebarBlocks.vue"),
      SocialIcon: () => import(/* webpackChunkName: "social-icon" */ "../components/SocialIcon.vue")
    },
    computed: {
      hasSidebarBlocks() {
        return this.sidebar_blocks && this.sidebar_blocks.length > 0;
      }
    }
  };
</script>

<style scoped>
.content-column > div:not(:first-child) {
    margin-top: 16px;
}
.facebook-button,
.facebook-button a {
    color: #1982f4;
}
</style>