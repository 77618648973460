var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { class: ["social-icon", _vm.class_names], attrs: { href: _vm.url } },
    [
      _c(
        "base-icon",
        {
          attrs: {
            "icon-name": _vm.type,
            width: _vm.icon_size,
            height: _vm.icon_size,
            viewbox: _vm.viewBoxValues[_vm.type] || "0 0 40 40"
          }
        },
        [
          _vm.type === "youtube" ? _c("icon-youtube") : _vm._e(),
          _vm._v(" "),
          _vm.type === "instagram" ? _c("icon-instagram") : _vm._e(),
          _vm._v(" "),
          _vm.type === "twitter" ? _c("icon-twitter") : _vm._e(),
          _vm._v(" "),
          _vm.type === "linkedin" ? _c("icon-linkedin") : _vm._e(),
          _vm._v(" "),
          _vm.type === "facebook" ? _c("icon-facebook") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }