var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: [
        "block mixed-content-block",
        _vm.background_color,
        _vm.background_color === "bg-white" ? "white-block" : ""
      ]
    },
    [
      _c(
        "div",
        {
          class: [
            _vm.include_container
              ? "container mx-auto px-5-safe"
              : "full-container",
            "py-8 lg:py-10 xl:py-12"
          ]
        },
        [
          _c("div", { class: _vm.hasSidebarBlocks ? "lg:flex" : "" }, [
            _c(
              "div",
              {
                class: [
                  "content-column",
                  _vm.hasSidebarBlocks
                    ? "mb-10 lg:mb-0 lg:w-3/4 lg:pr-12 xl:pr-16"
                    : ""
                ]
              },
              [
                _vm.heading
                  ? _c(
                      "div",
                      [
                        _c("base-heading", { attrs: { size: "h1" } }, [
                          _vm._v(_vm._s(_vm.heading))
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.has_text
                  ? _c("div", [_c("base-rich-text", [[_vm._t("text")]], 2)], 1)
                  : _vm._e(),
                _vm._v(" "),
                _vm.facebook_button
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "facebook-button inline-flex items-center text-lg"
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("social-icon", {
                              attrs: {
                                url: _vm.facebook_button.url,
                                type: "facebook",
                                class_names: "inline-block",
                                icon_size: "32"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "pl-3" }, [
                          _c(
                            "a",
                            { attrs: { href: _vm.facebook_button.url } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.facebook_button.text
                                      ? _vm.facebook_button.text
                                      : "Find us on Facebook"
                                  ) +
                                  "\n                        "
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.image
                  ? _c(
                      "div",
                      [
                        _c("base-image", {
                          attrs: {
                            src: _vm.image.src,
                            retina_src: _vm.image.retina,
                            srcset_webp: _vm.image.webpSrcset,
                            class_names: "mx-auto",
                            alt: _vm.image.alt
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _vm.hasSidebarBlocks
              ? _c(
                  "div",
                  { staticClass: "lg:w-1/4" },
                  [
                    _c("sidebar-blocks", {
                      attrs: { blocks: _vm.sidebar_blocks }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }